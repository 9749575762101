.images-wrap  {
	align-items:center;
}

.item-page {
	margin: auto;
	max-width: 1000px;
	align-items: center;
	margin-top: 50px;
	padding: 5px;
}

.item-image-box {
	margin: auto;
	max-width: 800px;
	width: 100%;
	text-align: -webkit-center;
}

.item-image {
	object-fit: contain;
	width: 390px;
	height: 500px;
}

@media (min-width: 768px) {
	.item-image {
		object-fit: contain;
		width: 600px;
		height: 900px;
	}
}

.main-info {
	display: flex;
	flex-wrap: wrap;
	background-color: #eee;
	max-width: 1000px;
	width: 100%;
	align-items:center;
}

.main-info-item {
	height: fit-content;
	flex: 1 250px;
	align-items: center;
	position: relative;
	display: block;
	padding: 5px 10px;
	display: flex;
	flex-direction: column;
	background-color: #ffffff;
	border: 1px solid #000000;
	text-align:center;
	place-content:center;
	min-height: 100px;
	height: 100%;
}

.main-info .last-up {
	line-height: 1.2;
	font-size: 25px;
	font-family: Nunito;
	font-weight: bold;
	flex: 1 150px;
}
.main-info .price {
	line-height: 1.2;
	font-size: 25px;
	font-family: Nunito;
	font-weight: bold;
	flex: 1 200px;
}

.main-info .rooms {
	line-height: 1.2;
	font-size: 25px;
	font-family: Nunito;
	font-weight: bold;
}

.main-info .owner {
	line-height: 1.2;
	font-size: 25px;
	font-family: Nunito;
	font-weight: bold;
	flex: 1 150px;
}

.main-info .contact {
	line-height: 1.2;
	font-size: 25px;
	font-family: Nunito;
	font-weight: bold;
	flex: 1 180px;
}

.main-info .telegram {
	line-height: 1.2;
	font-size: 25px;
	font-family: Nunito;
	font-weight: bold;
	flex: 1 180px;
}


.main-info .info {
	flex: 100%;
	align-items: start;
	text-align: left;
	padding: 10px;    
	max-width: 100%;
}


.main-info .address .address-value {
	align-self: start;
	flex: 100%;
	align-items: start;
	text-align: left;
	padding: 10px;
	line-height: 1.2;
	font-size: 20px;
	font-family: Nunito;
	font-weight: bold;
	margin-top: 10px;
	margin-bottom: 10px;
}

.item-map {
	height: 30vh;
	display: block;
	width: 100%;
}

.loading-wrapper{
    min-height: calc(100vh - 185px);
    width: 100%;
    margin: auto;
    min-width: 100vw;
    align-content: center;
    text-align: center;
}

.item-wrapper {
	display: block;
	flex: 1;
	align-items: center;
}

.not-found {
    align-content: center;
    height: 100%;
	margin: auto;
	text-align: center;
	font-size: 25px;
	font-family: Nunito;
	font-weight: bold;
}