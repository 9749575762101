
.app {
  display: flex;
  flex-direction: column;
}

.toggle-button-container {
  position: fixed;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
}

.toggle-button-container button {
  padding: 10px 20px;
  font-size: 16px;
}

.content-container {
  display: flex;
  flex-grow: 1;
  width: 100%;
}

.content-container.desktop {
  flex-direction: row;
}

.half-screen {
  width: 50% !important;
  height: 90vh;
  overflow-y: auto;
}

@media (min-width: 768px) {
  .app {
    flex-direction: row;
  }

  .content-container {
    flex-direction: row;
  }

  .toggle-button-container {
    display: none;
  }
}


.search-container {
  width: 100%;
}