.filters {
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 10px;
  background: #e4c580;
}

@media (max-width: 480px) {
  .filters {
    flex: 1 1;
  }
}

.filter {
  font-family: Nunito;
  max-width: 100%;
  display: flex;
  align-items: start;
  flex-direction: column;
  padding: 10px 20px;
  border: 1px solid #ddd;
  font-size: 1rem;
  background: white;
}

.filter-header {
  font-weight: 800;
}

.room-selection {
  display: flex;
  flex-wrap: wrap;
}

.city-select {
  text-align: center;
}

.room-button {
  padding: 5px 10px;
  border: 1px solid #ccc;
  background-color: #fff;
  cursor: pointer;
}

.room-button.selected {
  background-color: rgb(8, 15, 91);
  color: #fff;
  border-color: rgb(8, 15, 91);
}

.price-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.price-input {
  position: relative;
  display: inline-block;
}

.price-input input {
  padding-right: 20px;
  max-width: 70px;
  padding: 5px;
  border: 1px solid #ccc;
}

.price-input .currency-symbol {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.city-selection {
  padding: 5px 10px;
  border: 1px solid #ccc;
  background-color: #fff;
  cursor: pointer;
}


.telegram-input {
  position: relative;
  display: flex;
  align-items: center;
  gap: 3px;
}