.listings {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  background-color: #eee;
  max-height: 90vh;
  overflow-y: scroll;
}

.listing {
  height: fit-content;
  max-width: calc(100% - 10px);
  position: relative;
  display: block;
  margin: 3px;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 8px;
}

.listing-image {
  object-fit: cover;
  display: block;
  width: 350px;
  height: 290px;
}

.listing-img {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  overflow: hidden;
  margin: 10px 10px 0 10px;
  position: relative;
  text-align: -webkit-center;
}

.listing-content {
  bottom: 0;
  left: 10px;
  position: absolute;
  right: 10px;
  top: 10px;
  box-sizing: border-box;
  display: block;
}


.listing-details {
  padding: 5px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.listing-details h3 {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
}

.listing-details .price-usd {
  font-size: 1rem;
  width: fit-content;
  padding-top: .125rem;
  padding-bottom: .125rem;
  padding-left: .375rem;
  padding-right: .375rem;
  border-radius: .125rem;
  color: rgb(29 33 38);
  font-weight: 600;
  background-color: #ffd954;
}

.listing-details p {
  margin: 0;
  font-size: 2.5vw;
  font-weight: 500;
}

@media screen and (min-width: 768px) {
  .listing-details p {
    font-size: 14px;
  }
}

.listing-details .top {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.listing-details span {
  font-size: 0.7rem;
}

.description {
  font-size: 10px;
  color: #555;
}

.metro-info {
  display: flex;
  align-items: center;
  gap: 5px;
}

.metro-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.metro-dot.orange {
  background-color: orange;
}

.metro-dot.blue {
  background-color: blue;
}

.favorite-button {
  background: none;
  border: none;
  color: #ccc;
  font-size: 18px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.favorite-button:hover {
  color: red;
}

.offer-type {
  font-size: 0.7rem;
  position: absolute;
  top: 2px;
  left: 2px;
  width: fit-content;
  display: block;
  padding: 2px 8px;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: .1em;
  border-radius: 4px;
  background: rgb(8, 15, 91);
}

.offer-price {
  bottom: 15px;
  font-size: 25px;
  font-family: "Nunito";
  color: #ffffff;
  font-weight: bold;
  left: 15px;
  line-height: 1.2;
  position: absolute;
  font-family: Nunito;
}

.offer-rooms {
  bottom: 15px;
  font-size: 25px;
  font-family: "Nunito";
  color: #ffffff;
  font-weight: bold;
  right: 15px;
  line-height: 1.2;
  position: absolute;
  font-family: Nunito;
}

.offer-time {
  position: absolute;
  top: 2px;
  right: 2px;
  margin-right: 5px;
  bottom: 15px;
  font-size: 0.9rem;
  color: #ffffff;
  font-weight: 400;
  right: 5px;
  line-height: 1.2;
  position: absolute;
  font-family: Nunito;
}

.listing-img {
  position: relative;
  display: block;
}

.listing-rooms {
  margin-right: 10px;
  font-weight: 900;
  font-size: 1rem;
}

.listing-address {

  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 0.9rem;
  font-family: "Nunito";
  color: #484848;
  overflow: hidden;
  text-overflow: hidden;
  max-lines: 1;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}


@media (max-width: 1024px) {
  .listing {
    flex: 1 1;
  }
}

@media (max-width: 768px) {
  .listing {
    flex: 1 1;
  }
}